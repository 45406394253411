import React from "react";
import { Form, Input, Select, Row, Col, Button, InputNumber } from "antd";

const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;

export default class VehicleForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      isCompanyDisabled: false,
      isDepartmentDisabled: false,
      isProjectDisabled: false,
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) debugger;

      if (!err) {
        this.props.onFormSubmit(this.props.objectId, values);
      }
    });
  }

  componentDidMount() {
    const keys = {};

    if (this.props.object) {
      Object.keys(this.props.object).forEach((key) => {
        keys[key] = this.props.object[key];
      });
    }

    const state = {
      isCompanyDisabled: false,
      isDepartmentDisabled: false,
      isProjectDisabled: false,
    };

    if (this.props.companies.length === 1) {
      state.isCompanyDisabled = true;
      keys.companyId = this.props.companies[0]._id;
    }

    if (this.props.departments.length === 1) {
      state.isDepartmentDisabled = true;
      keys.departmentId = this.props.departments[0]._id;
    }

    if (this.props.projects.length === 1) {
      state.isProjectDisabled = true;
      keys.projectId = this.props.projects[0]._id;
    }

    this.props.form.setFieldsValue(keys);
    this.setState({ ...state });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };

    const companies = this.props.companies.map((e) => {
      return (
        <Option key={e._id} value={e._id}>
          {e.title}
        </Option>
      );
    });

    const departments = this.props.departments.map((e) => {
      return (
        <Option key={e._id} value={e._id}>
          {e.title}
        </Option>
      );
    });
    const projects = this.props.projects.map((e) => {
      return (
        <Option key={e._id} value={e._id}>
          {e.title}
        </Option>
      );
    });
    const suppliers = this.props.suppliers.map((e) => {
      return (
        <Option key={e._id} value={e._id}>
          {e.title}
        </Option>
      );
    });
    const equipmentStates = this.props.equipmentStates.map((e) => {
      return (
        <Option key={e._id} value={e._id}>
          {e.title}
        </Option>
      );
    });
    const vehicleTypes = this.props.vehicleTypes.map((e) => {
      return (
        <Option key={e._id} value={e._id}>
          {e.title}
        </Option>
      );
    });

    const users = this.props.users.map((e) => {
      return (
        <Option key={e._id} value={e._id}>
          {e.name}
        </Option>
      );
    });

    const formButtonTitle = this.props.objectId
      ? "Lagre endringer"
      : "Opprett nytt kjøretøy";

    return (
      <Form onSubmit={this.handleSubmit}>
        <FormItem {...formItemLayout} label="Firma">
          {getFieldDecorator("companyId", {
            rules: [{ required: true, message: "Vennligst velg firma" }],
          })(
            <Select disabled={this.state.isCompanyDisabled}>{companies}</Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Prosjekt">
          {getFieldDecorator("projectId")(
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Prosjekt"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {projects}
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Leverandør">
          {getFieldDecorator("supplierId")(
            <Select
              showSearch
              placeholder="Velg en leverandør"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {suppliers}
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Status">
          {getFieldDecorator("state", {
            rules: [{ required: true, message: "Vennligst velg status" }],
          })(<Select>{equipmentStates}</Select>)}
        </FormItem>

        <FormItem {...formItemLayout} label="Beskrivelse">
          {getFieldDecorator("description")(<TextArea type="text" rows={2} />)}
        </FormItem>

        <FormItem {...formItemLayout} label="Kommentar">
          {getFieldDecorator("comment")(<TextArea type="text" rows={2} />)}
        </FormItem>

        <FormItem {...formItemLayout} label="Ansvarlige">
          {getFieldDecorator("responsibles", {
            rules: [
              {
                required: true,
                message: "Vennligst velg minimum en ansvarlig",
              },
            ],
          })(
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Ansvarlige"
            >
              {users}
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Registreringsnummer">
          {getFieldDecorator("registrationNumber", {
            rules: [
              {
                required: true,
                message: "Vennligst fyll inn et gyldig registreringsnummer",
                pattern: "^([A-Å]{1,2})([0-9]{4,5})",
              },
            ],
          })(<Input type="text" />)}
        </FormItem>

        <FormItem {...formItemLayout} label="Merke">
          {getFieldDecorator("brand", {
            rules: [{ required: true, message: "Vennligst velg merke" }],
          })(
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Bilmerke"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {vehicleTypes}
            </Select>
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Modell">
          {getFieldDecorator("model", {
            rules: [{ required: true, message: "Vennligst velg merke" }],
          })(
            <Input type="text" placeholder="Merke (Eks: Amaraok, Golf, ...) " />
          )}
        </FormItem>

        <FormItem {...formItemLayout} label="Årsmodell">
          {getFieldDecorator("year", {
            rules: [
              {
                required: true,
                message: "Vennligst fyll inn en gyldig årsmodell",
                type: "number",
                min: 1000,
                max: 9999,
              },
            ],
          })(<InputNumber placeholder="Årsmodell (Eks: 2012, 2013, ...)" />)}
        </FormItem>

        <FormItem>
          <Row>
            <Col span={4} />
            <Col span={20}>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.props.isSaving}
              >
                {formButtonTitle}
              </Button>
            </Col>
          </Row>
        </FormItem>
      </Form>
    );
  }
}
