import React from 'react';

import { Form, Row, Col, Card, Button, Icon, Popconfirm, notification } from 'antd';

import { Suppliers, Vehicles, VehicleTypes, InventoryStates, Milestones } from '../utils/em-client';
import { Companies, Departments, Projects } from '../utils/st-client';

import VehicleForm from "./forms/VehicleForm";
import MilestonesTable from "./MilestonesTable";
import { Link } from 'react-router-dom';

import Auth from '../utils/Auth';
import { Users } from "../utils/em-client";

export default class VehiclesEditor extends React.Component {

  constructor(props) {
    super(props);

    const objectId = this.props.match.params.id;

    this.state = {
      objectId: objectId,
      object: null,
      milestones: [],
      isNew: !objectId,
      isLoading: false,
      isSaving: false,
      companies: [],
      departments: [],
      projects: [],
      suppliers: [],
      states: [],
      users: [],
      equipmentStates: [],
      vehicleTypes: []
    };

    this.onSaveElement = this.onSaveElement.bind(this);
    this.onDeleteElement = this.onDeleteElement.bind(this);
    this.onMilestonesUpdate = this.onMilestonesUpdate.bind(this);
  }

  componentDidMount() {

    const companyId = Auth.getCompany();
    const departmentIds = Auth.getDepartments();
    const projects = Auth.getProjects();

    Suppliers.get().then(result => {
      this.setState({ suppliers: result });
    }).catch(error => {

    });

    InventoryStates.get().then(result => {
      this.setState({ equipmentStates: result });
    }).catch(error => {

    });

    VehicleTypes.get().then(result => {
      this.setState({ vehicleTypes: result });
    }).catch(error => {

    });

    Departments.getByIds(departmentIds).then(result => {
      this.setState({ departments: result });
    });

    Projects.getAll().then(result => {
      this.setState({ projects: result });
    });

    Companies.get(companyId).then(result => {
      const companies = this.state.companies;
      companies.push(result);
      this.setState({ companies: companies });
    });

    Projects.getByDepartment(departmentIds).then(result => {
      this.setState({ projects: result });
    });

    Users.getByCompany(companyId).then(result => {
      this.setState({ users: result });
    });

    if (this.state.isNew)
      return;


    Vehicles.get(this.state.objectId).then(result => {
      this.setState({ object: result });
    }).catch(error => {

    });

    Milestones.getByParentId(this.state.objectId).then(result => {
      this.setState({ milestones: result });
    });

  }

  onSaveElement(objectId, value) {
    this.setState({ isSaving: true });
    value.documents = [];
    value.equipment = [];

    if (!value.milestones) value.milestones = [];
    if (!value.projectId) value.projectId = "";
    if (!value.description) value.description = "";
    if (!value.comment) value.comment = "";

    if (!objectId) {

      Vehicles.create(value).then(result => {
        notification.success({ message: "Opprettet nytt kjøretøy." });
        this.setState({ objectId: result._id, object: result, isNew: false, isSaving: false });
      }).catch(error => {
        notification.error({ message: "Det oppsto en feil under oppretting av kjøretøyet." });
        this.setState({ isSaving: false });
      });
    } else {
      Vehicles.update(objectId, value).then(result => {
        notification.success({ message: "Oppdateringen av kjøretøyet var vellykket." });
        this.setState({ objectId: result._id, object: result, isNew: false, isSaving: false });
      }).catch(error => {
        notification.error({ message: "Det oppsto en feil under oppdateringen av kjøretøyet." });
        this.setState({ isSaving: false });
      });
    }

  }

  onDeleteElement() {
    if (!this.state.objectId)
      return;

    Vehicles.remove(this.state.objectId).then(result => {
      notification.info({ message: "Kjøretøyet ble slettet fra databasen." });
      this.props.history.push('/vehicles');
    }).catch(error => {
      notification.error({ message: "Det oppsto en feil under sletting av kjøretøy." });
    });
  }

  onMilestonesUpdate() {
    Milestones.getByParentId(this.state.objectId).then(result => {
      this.setState({ milestones: result });
    });
  }

  render() {

    const cardStyle = { marginTop: "20px" };
    const buttonStyle = { marginRight: "20px" };

    const props = { onFormSubmit: this.onSaveElement, ...this.state };

    const WrappedVehicleForm = Form.create()(VehicleForm);

    const isVehicleInfoUrlDisabled = !this.state.object;
    const vehicleInfoUrl = !isVehicleInfoUrlDisabled ? "https://www.vegvesen.no/Kjoretoy/Kjop+og+salg/Kj%C3%B8ret%C3%B8yopplysninger?registreringsnummer=" + this.state.object.registrationNumber : "";
    const linkStyle = { textDecoration: "inherit", color: "inherit" };

    return (
      <div>
        <Card>
          <Link to={"/vehicles"} style={buttonStyle}>
            <Button type="dashed" icon="arrow-left">Tilbake</Button>
          </Link>
          <Popconfirm title="Er du sikker på at du vil slette kjøretøyet?" cancelText="Avbryt" onConfirm={this.onDeleteElement} icon={<Icon type="delete" theme="outlined" style={{ color: 'red' }} />}>
            <Button type="danger" icon="delete" disabled={!this.state.objectId} style={buttonStyle}>Slett kjøretøy</Button>
          </Popconfirm>

          <Button icon="link" disabled={isVehicleInfoUrlDisabled}>
            <a target="_blank" rel="noopener noreferrer" href={vehicleInfoUrl} style={linkStyle}> Gå til offentlig informasjon om kjøretøyet</a>
          </Button>
        </Card>

        <Row gutter={20}>
          <Col span={12}>
            <Card style={cardStyle} title="Kjøretøy">
              <WrappedVehicleForm {...props} />
            </Card>
          </Col>
          <Col span={12}>
            <Card style={cardStyle} title="Oppgaver">
              <MilestonesTable parentObject={this.state.object} milestones={this.state.milestones} isDisabled={this.state.isNew} onUpdate={this.onMilestonesUpdate} />
            </Card>
          </Col>
        </Row>

      </div>
    );
  }
}